
import { reportStateColor } from "@/utils/report";
import { Component, Vue } from "vue-property-decorator";
import ReportListItem from "@/components/report/ReportListItem";

@Component({
  components: {
    ReportListItem,
  },
})
export default class HomeView extends Vue {
  get user(): Record<string, any> {
    return this.$store.getters.loggedUser;
  }

  get groupedReports(): Record<string, any> {
    return this.user?.reports
      ?.sort((a, b) => +(new Date(b.created_on) - +new Date(a.created_on)))
      ?.filter((report) => report.state !== "completed")
      ?.slice(0, 10)
      ?.reduce((groups, report) => {
        const index = this.$moment(report.created_on).format("MMMM YYYY");
        if (groups[index] === undefined) groups[index] = [report];
        else groups[index].push(report);
        return groups;
      }, {});
  }

  reportStateColor(value) {
    return reportStateColor(value);
  }

  mounted() {
    this.$store.dispatch("loadUserInfo");
  }
}
