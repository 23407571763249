
import { Component, Vue } from "vue-property-decorator";

@Component
export default class FormCompileView extends Vue {
  form = {};
  thankYouDialog = false;
  addOwnerDialog = false;
  addDeputyDialog = false;
  policy_check = false;
  newOwner = {};
  newDeputy = {};
  labels = {
    en: {
      company_name: "Company Name",
      company_type: "Legal Form",
      tax_code: "Tax Code",
      vat_code: "Company Number",
      sae: "",
      company_address: "Registered Office",
      zip_code: "ZIP Code",
      city: "City",
      sic_code: "SIC Code",
      phone: "Phone",
      email: "Email",
      add_bo: "Add Beneficial Owner",
      person_name: "Name",
      person_surname: "Surname",
      ssn: "SSN/National Insurance Number",
      person_address: "Address",
      document_type: "Document Type",
      document_items: ["Identity Card", "Driving License", "Passport"],
      document_number: "Document Number",
      issued: "Issued at",
      issued_place: "Issuing Authority and Place of issue",
      expires_on: "Valid until",
      add_representative: "Add Company Representative",
      person_role: "Role",
      person_nationality: "Nationality",
      dob: "Date of Birth",
      cob: "City of Birth",
      attachments: "Attachments",
      page_title: "Fill out form",
      page_subtitle:
        "Fill out the form below in order to continue the practice",
      main_section_title_company: "Company Data",
      main_section_title_person: "Person Data",
      bo: "Beneficial Owners",
      add: "Add",
      cr: "Company Representatives",
      send_data: "Send Data",
      save: "Save",
      no_bo: "No Beneficial Owner Added",
      no_cr: "No Company Representative Added",
      privacy_policy_check: "I have read and accepted the conditions of ",
    },
    it: {
      company_name: "Ragione Sociale",
      company_type: "Forma Giuridica",
      tax_code: "Codice Fiscale",
      vat_code: "Partita IVA",
      company_address: "Sede Legale",
      zip_code: "CAP",
      city: "Città",
      sae: "SAE",
      sic_code: "Codice ATECO2007",
      phone: "Telefono",
      email: "Email",
      add_bo: "Aggiungi Titolare Effettivo",
      person_name: "Nome",
      person_surname: "Cognome",
      ssn: "Codice Fiscale",
      person_address: "Indirizzo di Residenza",
      document_type: "Tipo di Documento",
      document_items: ["Carta d'identità", "Patente", "Passaporto"],
      document_number: "Numero Documento",
      issued: "Rilasciato il",
      issued_place: "Luogo e Autorità di rilascio",
      expires_on: "Valido fino a",
      add_representative: "Aggiungi Rappresentante",
      person_role: "Ruolo",
      person_nationality: "Nazionalità",
      dob: "Data di Nascita",
      cob: "Città di Nascita",
      attachments: "Allegati",
      page_title: "Compila questionario",
      page_subtitle:
        "Compila il form sottostante in modo da portare avanti la pratica",
      main_section_title_company: "Dati Azienda",
      main_section_title_person: "Dati Persona",
      bo: "Titolare Effettivo (UBO)",
      add: "Aggiungi",
      cr: "Rappresentati d'impresa",
      send_data: "Invia Dati",
      save: "Salva",
      no_bo: "Nessun titolare inserito",
      no_cr: "Nessun rappresentante inserito",
      privacy_policy_check:
        "Dichiaro di aver letto e accettato le condizioni riportate all'interno della ",
    },
  };

  getLabel(label) {
    return this.labels[this.$route.query.lang || "it"][label];
  }

  async compileForm(formData): void {
    const { files, ...form } = formData;
    form.requesterId = this.$store.getters.loggedUser.id;
    const result = await this.$store.dispatch("compileForm", {
      id: form.id,
      form,
    });
    if (result !== undefined) {
      console.log(result, files);
      Promise.all(
        (files === undefined ? [] : files).map((file) => {
          const formData = new FormData();
          formData.append("file", file, file.name);
          return this.axios.post(`/form/${result.id}/attachment`, formData);
        })
      ).then((result) => {
        if (result.every((r) => r !== undefined)) this.thankYouDialog = true;
      });
    }
  }

  async mounted() {
    this.form = await this.$store.dispatch("loadForm", {
      id: this.$route.params.id,
    });
  }

  youReWellcome() {
    window.location.href = "https://google.com";
  }

  addOwner(owner) {
    if (!owner) this.addOwnerDialog = true;
    else {
      Array.isArray(this.form.owners)
        ? this.form.owners.push(owner)
        : (this.form.owners = [owner]);
      this.newOwner = {};
      this.addOwnerDialog = false;
    }
  }

  addDeputy(deputy) {
    if (!deputy) this.addDeputyDialog = true;
    else {
      Array.isArray(this.form.deputies)
        ? this.form.deputies.push(deputy)
        : (this.form.deputies = [deputy]);
      this.newDeputy = {};
      this.addDeputyDialog = false;
    }
  }
}
