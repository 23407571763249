
import { Component, Vue } from "vue-property-decorator";

@Component
export default class CreateReportView extends Vue {
  reportTypes = [
    {
      text: "Persona Giuridica",
      value: "company",
    },
    {
      text: "Persona Fisica",
      value: "person",
    },
  ];

  report = {
    send_to: null,
  };

  pivaRules = [
    (v: string) => !!v || "La partita IVA è obbligatoria",
    (v: string) =>
      /^[0-9]{11}$/gm.test(v) ||
      "La partita IVA deve contenere esattamente 11 cifre",
  ];

  ciRules = [
    (v: string) => !!v || "Il codice fiscale è obbligatorio",
    (v: string) =>
      /^[a-zA-Z]{6}[0-9]{2}[a-zA-Z][0-9]{2}[a-zA-Z][0-9]{3}[a-zA-Z]$/gm.test(
        v
      ) ||
      "Il codice fiscale deve rispettare questo formato AAA BBB 12 C 34 D 567 E",
  ];

  async createReport(reportData): void {
    const { files, send_to, ...report } = reportData;
    report.requesterId = this.$store.getters.loggedUser.id;
    const result = await this.$store.dispatch("createReport", { report });
    if (result !== undefined) {
      console.log(result, files, send_to);
      Promise.all([
        ...(files === undefined ? [] : files).map((file) => {
          const formData = new FormData();
          formData.append("file", file, file.name);
          return this.axios.post(`/report/${result.id}/attachment`, formData);
        }),
        this.axios.post(`/report/${result.id}/send-form?mail=${send_to}`),
      ]).then(() => {
        this.$router.push("/home");
      });
    }
  }
}
