import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
// eslint-disable-next-line @typescript-eslint/no-var-requires
const moment = require("moment");
require("moment/locale/it");
import axios from "axios";
import VueAxios from "vue-axios";
import {
  reportStateColor,
  reportStateIcon,
  reportStateLabel,
} from "@/utils/report";

Vue.config.productionTip = false;

// eslint-disable-next-line @typescript-eslint/no-var-requires
Vue.use(require("vue-moment"), { moment });
axios.defaults.baseURL = "https://riskplatform.online:3003";
//axios.defaults.baseURL = "http://192.168.1.2:3003";
Vue.use(VueAxios, axios);

Vue.filter("reportStateLabel", reportStateLabel);
Vue.filter("reportStateColor", reportStateColor);
Vue.filter("reportStateIcon", reportStateIcon);

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
