
import { Component, Vue } from "vue-property-decorator";
import ReportListItem from "@/components/report/ReportListItem";

@Component({
  components: {
    ReportListItem,
  },
})
export default class ReportListView extends Vue {
  get reports() {
    return this.$store.getters.allReports;
  }

  get user() {
    return this.$store.getters.loggedUser;
  }

  loadReports() {
    this.$store.dispatch("loadAllReports");
  }

  mounted() {
    this.loadReports();
  }

  updateReport({ id, report }) {
    this.$store.dispatch("updateReport", {
      id,
      report,
    });
  }
}
