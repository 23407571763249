<template>
  <div>
    <v-list-item @click="openDetailsDialog(report)">
      <v-list-item-content>
        <v-list-item-title>
          Ricerca {{ report.id }} - {{ report.type }}: {{ report.name }}
          {{ report.cfpi }}
        </v-list-item-title>
        <v-list-item-subtitle>
          Richiesta:
          {{ report.created_on | moment("DD/MM/YYYY") }} - Stato:
          <span :style="{ color: reportStateColor(report.state) }">
            {{ report.state | reportStateLabel }}
          </span>
        </v-list-item-subtitle>
      </v-list-item-content>
      <v-list-item-action style="cursor: pointer">
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon v-bind="attrs" v-on="on">
              <v-icon>mdi-dots-vertical</v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item @click.prevent="deleteReport(report)">
              <v-list-item-avatar>
                <v-icon>mdi-delete</v-icon>
              </v-list-item-avatar>
              <v-list-item-title>Elimina</v-list-item-title>
            </v-list-item>
            <v-list-item @click.prevent="openDetailsDialog(report)">
              <v-list-item-avatar>
                <v-icon>mdi-magnify</v-icon>
              </v-list-item-avatar>
              <v-list-item-title>Dettagli</v-list-item-title>
            </v-list-item>
            <v-list-item
              @click.prevent="wipReport(report)"
              v-if="report.state === 'required'"
            >
              <v-list-item-avatar>
                <v-icon color="orange">mdi-timer-sand</v-icon>
              </v-list-item-avatar>
              <v-list-item-title>Work in progress</v-list-item-title>
            </v-list-item>
            <v-list-item
              @click.prevent="downloadPDF(report)"
              v-if="report.state === 'completed'"
            >
              <v-list-item-avatar>
                <v-icon color="red">mdi-file-pdf-box</v-icon>
              </v-list-item-avatar>
              <v-list-item-title>Scarica PDF</v-list-item-title>
            </v-list-item>
            <v-list-item
              @click.prevent="openUploadDialog(report)"
              v-if="report.state === 'wip' && user.role === 'admin'"
            >
              <v-list-item-avatar>
                <v-icon color="red">mdi-file-pdf-box</v-icon>
              </v-list-item-avatar>
              <v-list-item-title>Carica PDF</v-list-item-title>
            </v-list-item>
            <!--
            <v-list-item
              @click.prevent="sendQuestionForm(report)"
              v-if="report.state !== 'completed'"
            >
              <v-list-item-avatar>
                <v-icon color="primary">mdi-send</v-icon>
              </v-list-item-avatar>
              <v-list-item-title>Invia questionario</v-list-item-title>
            </v-list-item>
            -->
          </v-list>
        </v-menu>
      </v-list-item-action>
    </v-list-item>
    <v-divider></v-divider>

    <v-dialog persistent v-model="detailsDialog" max-width="450">
      <v-card v-if="detailsType === 'report'">
        <v-card-title>
          Dettagli richiesta
          <v-spacer></v-spacer>
          <v-btn icon @click="detailsDialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-subtitle>Consulta i dettagli della richiesta</v-card-subtitle>
        <v-card-text v-if="selectedReport">
          <v-simple-table>
            <template v-slot:default>
              <tbody>
                <tr>
                  <td><b>Tipo</b></td>
                  <td>{{ selectedReport.type }}</td>
                </tr>
                <template v-if="selectedReport.type === 'company'">
                  <tr>
                    <td><b>Ragione Sociale</b></td>
                    <td>{{ selectedReport.name }}</td>
                  </tr>
                  <tr>
                    <td><b>Partita IVA</b></td>
                    <td>{{ selectedReport.cfpi }}</td>
                  </tr>
                </template>
                <template v-if="selectedReport.type === 'person'">
                  <tr>
                    <td><b>Nome e Cognome</b></td>
                    <td>{{ selectedReport.name }}</td>
                  </tr>
                  <tr>
                    <td><b>Codice Fiscale</b></td>
                    <td>{{ selectedReport.cfpi }}</td>
                  </tr>
                  <tr>
                    <td><b>Data di Nascita</b></td>
                    <td>{{ selectedReport.birth_date }}</td>
                  </tr>
                  <tr>
                    <td><b>Paese di Nascita</b></td>
                    <td>{{ selectedReport.birth_country }}</td>
                  </tr>
                  <tr>
                    <td><b>Nazionalità</b></td>
                    <td>{{ selectedReport.country }}</td>
                  </tr>
                </template>
              </tbody>
            </template>
          </v-simple-table>
          <v-subheader>
            Allegati ({{ selectedReport.attachments.length }})
          </v-subheader>
          <v-list>
            <v-list-item
              @click="downloadAttachment(attachment)"
              v-for="attachment in selectedReport.attachments"
              :key="attachment.id"
            >
              <v-list-item-avatar>
                <v-icon>mdi-paperclip</v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                {{ attachment.filename }}
              </v-list-item-content>
            </v-list-item>
          </v-list>
          <v-subheader>
            Questionari ({{ selectedReport.forms.length }})
          </v-subheader>
          <v-list>
            <v-list-item v-for="form in selectedReport.forms" :key="form.id">
              <v-list-item-avatar>
                <v-icon>mdi-form-select</v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>{{ form.for_email }}</v-list-item-title>
                <v-list-item-subtitle>
                  stato:
                  <span :style="{ color: reportStateColor(form.state) }">
                    {{ form.state | reportStateLabel }}
                  </span>
                </v-list-item-subtitle>
                <v-list-item-subtitle>
                  ultimo aggiornamento:
                  {{ form.updated_on | moment("DD/MM/YYYY HH:mm") }}
                </v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-action
                v-if="form.state === 'completed'"
                @click="(selectedForm = form) && (detailsType = 'form')"
              >
                <v-icon>mdi-chevron-right</v-icon>
              </v-list-item-action>
            </v-list-item>
          </v-list>
        </v-card-text>
      </v-card>
      <v-card v-if="detailsType === 'form'">
        <v-card-title>
          Dettagli Questionario
          <v-spacer></v-spacer>
          <v-btn
            icon
            @click="(detailsType = 'report') && (selectedForm = null)"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-subtitle>Consulta i dettagli del questionario</v-card-subtitle>
        <v-card-text v-if="selectedForm">
          <v-simple-table>
            <template v-slot:default>
              <tbody>
                <tr>
                  <td><b>Tipo</b></td>
                  <td>{{ selectedForm.type }}</td>
                </tr>
                <tr>
                  <td><b>Email Compilatore</b></td>
                  <td>{{ selectedForm.for_email }}</td>
                </tr>
                <template v-if="selectedForm.type === 'company'">
                  <tr>
                    <td><b>Ragione Sociale</b></td>
                    <td>{{ selectedForm.name }}</td>
                  </tr>
                  <tr>
                    <td><b>Codice Fiscale</b></td>
                    <td>{{ selectedForm.cf }}</td>
                  </tr>
                  <tr>
                    <td><b>Partita IVA</b></td>
                    <td>{{ selectedForm.pi }}</td>
                  </tr>
                  <tr>
                    <td><b>Sede Legale</b></td>
                    <td>{{ selectedForm.address }}</td>
                  </tr>
                  <tr>
                    <td><b>CAP</b></td>
                    <td>{{ selectedForm.cap_code }}</td>
                  </tr>
                  <tr>
                    <td><b>Città</b></td>
                    <td>{{ selectedForm.city }}</td>
                  </tr>
                  <tr>
                    <td><b>SAE</b></td>
                    <td>{{ selectedForm.sae_code }}</td>
                  </tr>
                  <tr>
                    <td><b>Codice ATECO2007</b></td>
                    <td>{{ selectedForm.ateco2007_code }}</td>
                  </tr>
                  <tr>
                    <td><b>Email</b></td>
                    <td>{{ selectedForm.email }}</td>
                  </tr>
                  <tr>
                    <td><b>Telefono</b></td>
                    <td>{{ selectedForm.phone }}</td>
                  </tr>
                </template>
                <template v-if="selectedForm.type === 'person'">
                  <tr>
                    <td><b>Nome e Cognome</b></td>
                    <td>{{ selectedForm.name }}</td>
                  </tr>
                  <tr>
                    <td><b>Codice Fiscale</b></td>
                    <td>{{ selectedForm.cf }}</td>
                  </tr>
                  <tr>
                    <td><b>Data di Nascita</b></td>
                    <td>{{ selectedForm.birth_date }}</td>
                  </tr>
                  <tr>
                    <td><b>Paese di Nascita</b></td>
                    <td>{{ selectedForm.birth_country }}</td>
                  </tr>
                  <tr>
                    <td><b>Nazionalità</b></td>
                    <td>{{ selectedForm.country }}</td>
                  </tr>
                  <tr>
                    <td><b>Indirizzo di residenza</b></td>
                    <td>{{ selectedForm.address }}</td>
                  </tr>
                  <tr>
                    <td><b>CAP</b></td>
                    <td>{{ selectedForm.cap_code }}</td>
                  </tr>
                  <tr>
                    <td><b>Città di residenza</b></td>
                    <td>{{ selectedForm.city }}</td>
                  </tr>
                  <tr>
                    <td><b>Email</b></td>
                    <td>{{ selectedForm.email }}</td>
                  </tr>
                  <tr>
                    <td><b>Telefono</b></td>
                    <td>{{ selectedForm.phone }}</td>
                  </tr>
                </template>
              </tbody>
            </template>
          </v-simple-table>
          <v-simple-table>
            <template v-slot:default>
              <tbody></tbody>
            </template>
          </v-simple-table>
          <v-subheader>
            Allegati ({{ selectedForm.attachments.length }})
          </v-subheader>
          <v-list>
            <v-list-item
              @click="downloadAttachment(attachment)"
              v-for="attachment in selectedForm.attachments"
              :key="attachment.id"
            >
              <v-list-item-avatar>
                <v-icon>mdi-paperclip</v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                {{ attachment.filename }}
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog persistent v-model="uploadDialog" max-width="450">
      <v-card>
        <v-card-title>
          Carica File
          <v-spacer></v-spacer>
          <v-btn icon @click="uploadDialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-subtitle>
          Carica il report relativo a questa ricerca
        </v-card-subtitle>
        <v-card-text>
          <v-file-input
            @change="fileChanged"
            label="Seleziona file"
            truncate-length="15"
            :show-size="1024"
          ></v-file-input>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red" dark @click="uploadDialog = false">
            <v-icon>mdi-cancel</v-icon>
            Annulla
          </v-btn>
          <v-btn dark color="green" @click="uploadFile">
            <v-icon>mdi-check</v-icon>
            Carica
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="sendFormDialog" max-width="450">
      <v-card>
        <v-card-title>Invia Questionario</v-card-title>
        <v-card-subtitle
          >Invia questionario ad un indirizzo email</v-card-subtitle
        >
        <v-card-text>
          <v-text-field
            v-model="emailToSend"
            prepend-inner-icon="mdi-mail"
            label="Indirizzo Email"
          ></v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="sendForm" color="primary">
            <v-icon>mdi-send</v-icon>
            Invia
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { Component, Vue } from "vue-property-decorator";
import { reportStateColor } from "@/utils/report";
import axios from "axios";

const ReportProps = Vue.extend({
  props: ["report", "user"],
});

@Component
export default class ReportListItem extends ReportProps {
  uploadDialog = false;
  files = null;
  detailsType = "report";
  emailToSend = null;
  selectedReport = null;
  selectedForm = null;
  detailsDialog = false;
  sendFormDialog = false;

  openUploadDialog(report) {
    this.uploadDialog = true;
    this.selectedReport = report;
  }

  downloadPDF(report) {
    //alert("hippicaiei figli di sottana");
    window.location.href = `${axios.defaults.baseURL}/report/${report.id}/download`;
  }

  downloadAttachment(attachment) {
    //alert("hippicaiei figli di sottana");
    window.location.href = `${axios.defaults.baseURL}/attachment/${attachment.id}/download`;
  }

  reportStateColor(value) {
    return reportStateColor(value);
  }

  wipReport(report) {
    this.$emit("update-report", {
      id: report.id,
      report: { state: "wip" },
    });
  }

  deleteReport(report) {
    this.$store.dispatch("deleteReport", { id: report.id });
  }

  uploadFile() {
    console.log("YEY");
    console.log(this.files);
    const formData = new FormData();
    formData.append("file", this.files, this.files.name);
    this.axios.post(`/report/${this.selectedReport.id}/upload`, formData);
    this.uploadDialog = false;
    this.files = null;
  }

  fileChanged(files) {
    this.files = files;
  }

  openDetailsDialog(report) {
    this.selectedReport = report;
    this.detailsDialog = true;
  }

  sendQuestionForm(report) {
    this.selectedReport = report;
    this.sendFormDialog = true;
  }

  sendForm() {
    this.axios.post(
      `/report/${this.selectedReport.id}/send-form?mail=${this.emailToSend}`
    );
    this.sendFormDialog = false;
    this.emailToSend = null;
  }
}
</script>

<style scoped></style>
