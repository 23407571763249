import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import HomeView from "@/views/HomeView.vue";
import LoginView from "@/views/LoginView.vue";
import store from "@/store";
import CreateReportView from "@/views/CreateReportView.vue";
import ReportListView from "@/views/ReportListView.vue";
import UserReportListView from "@/views/UserReportListView.vue";
import FormCompileView from "@/views/FormCompileView.vue";
import ResetDBView from "@/views/ResetDBView.vue";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/home",
    name: "Home",
    component: HomeView,
  },
  {
    path: "/create-report",
    name: "CreateReport",
    component: CreateReportView,
  },
  {
    path: "/report",
    name: "ReportListView",
    component: ReportListView,
  },
  {
    path: "/my-reports",
    name: "UserReportListView",
    component: UserReportListView,
  },
  {
    path: "/form/:id/compile",
    name: "FormCompileView",
    component: FormCompileView,
  },
  {
    path: "/reset-db",
    name: "ResetDatabase",
    component: ResetDBView,
  },
  {
    path: "/login",
    name: "Login",
    component: LoginView,
  },
];

const router = new VueRouter({
  mode: "hash",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach(async (to, from, next) => {
  console.log(!store.getters.isLoggedIn, to.name !== "Login");
  if (
    // make sure the user is authenticated
    !store.getters.isLoggedIn &&
    // ❗️ Avoid an infinite redirect
    to.name !== "Login" &&
    to.name !== "FormCompileView"
  ) {
    // redirect the user to the login page
    next({ name: "Login" });
  } else next();
});

export default router;
