
import { Component, Vue } from "vue-property-decorator";
import ReportListItem from "@/components/report/ReportListItem";

@Component({
  components: {
    ReportListItem,
  },
})
export default class UserReportListView extends Vue {
  get reports() {
    return this.$store.getters.allReports;
  }

  get user() {
    return this.$store.getters.loggedUser;
  }

  loadReports() {
    this.$store.dispatch("loadMyReports");
  }

  mounted() {
    this.loadReports();
  }
}
