import Vue from "vue";
import Vuex from "vuex";
import router from "@/router";
import axios from "axios";
import { LocalStrategy } from "../../back/src/auth/local.strategy";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    isLoggedIn: false,
    user: {},
    reports: [],
    forms: [],
    access_token: "",
  },
  mutations: {},
  actions: {
    async checkStoredConnection({ state, dispatch }) {
      //qui controllo se ho token salvati e li valido verso il server.
      const token = localStorage.getItem("access_token");
      if (token && token !== "") {
        axios.defaults.headers.common = {
          Authorization: `bearer ${token}`,
        };
        const userInfo = await dispatch("loadUserInfo");
        if (userInfo !== undefined) {
          //check del token andato a buon fine
          state.access_token = token;
          state.isLoggedIn = true;
          return userInfo;
        } else {
          //fallito il check del token
          axios.defaults.headers.common = {
            Authorization: "",
          };
          return false;
        }
      } else return false; //non ho token
    },
    async login({ state, dispatch }, { username, password }) {
      if (username && password) {
        return await Vue.axios
          .post("/auth/login", { username, password })
          .then((result) => {
            axios.defaults.headers.common = {
              Authorization: `bearer ${result.data.access_token}`,
            };
            state.access_token = result.data.access_token;
            state.isLoggedIn = true;
            localStorage.setItem("access_token", result.data.access_token);
            dispatch("loadUserInfo");
            return result.data;
          })
          .catch((error) => {
            state.isLoggedIn = false;
            console.error(error);
            return undefined;
          });
      }
      throw "login error";
    },
    logout({ state }) {
      state.isLoggedIn = false;
      state.user = {};
      localStorage.removeItem("access_token");
      router.push("/login");
    },

    loadUserInfo({ state }) {
      return Vue.axios
        .get("/user/me")
        .then((result) => {
          state.user = result.data;
          return result.data;
        })
        .catch((error) => {
          console.error(error);
          state.user = {};
          return undefined;
        });
    },

    createReport({ state }, { report }) {
      return Vue.axios
        .post("/report", report)
        .then((result) => {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          state.user.reports.push(result.data);
          return result.data;
        })
        .catch((error) => {
          console.error(error);
          return undefined;
        });
    },
    loadAllReports({ state }) {
      return Vue.axios
        .get("/report")
        .then((result) => {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          state.reports = result.data;
          return result.data;
        })
        .catch((error) => {
          console.error(error);
          return undefined;
        });
    },
    loadMyReports({ state }) {
      return Vue.axios
        .get("/user/me/reports")
        .then((result) => {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          state.reports = result.data;
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          state.user.reports = result.data;
          return result.data;
        })
        .catch((error) => {
          console.error(error);
          return undefined;
        });
    },
    updateReport({ state }, { id, report }) {
      return Vue.axios
        .patch(`/report/${id}`, report)
        .then((result) => {
          const index = state.reports.findIndex(
            (report: any) => report.id === result.data.id
          );
          if (index !== -1) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            state.reports[index] = result.data;
          }
          return result.data;
        })
        .catch((error) => {
          console.error(error);
          return undefined;
        });
    },
    deleteReport({ state }, { id }) {
      return Vue.axios.delete(`/report/${id}`).then(() => {
        const index = state.reports.findIndex(
          (report: any) => report.id === id
        );
        if (index !== -1) {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          state.reports.splice(index, 0);
        }
      });
    },

    loadForm({ state }, { id }) {
      return Vue.axios
        .get(`/form/${id}`)
        .then((result) => {
          const index = state.forms.findIndex(
            (form: any) => form.id === result.data.id
          );
          if (index !== -1) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            state.forms[index] = result.data;
          } else {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            state.forms.push(result.data);
          }
          console.log(result);
          return result.data;
        })
        .catch((error) => {
          console.error(error);
          return undefined;
        });
    },
    compileForm({ state }, { id, form }) {
      return Vue.axios
        .patch(`/form/${id}`, form)
        .then((result) => {
          const index = state.forms.findIndex(
            (form: any) => form.id === result.data.id
          );
          if (index !== -1) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            state.forms[index] = result.data;
          }
          return result.data;
        })
        .catch((error) => {
          console.error(error);
          return undefined;
        });
    },
  },
  modules: {},
  getters: {
    isLoggedIn: (state) => state.isLoggedIn,
    loggedUser: (state) => state.user,
    allReports: (state) => state.reports,
  },
});
