export const reportStateLabel = (value: string): string => {
  switch (value) {
    case "required":
      return "Inoltrato";
    case "wip":
      return "In Lavorazione";
    case "completed":
      return "Completato";
  }
  return "";
};

export const reportStateColor = (value: string): string => {
  switch (value) {
    case "required":
      return "blue";
    case "wip":
      return "orange";
    case "completed":
      return "green";
  }
  return "";
};

export const reportStateIcon = (value: string): string => {
  switch (value) {
    case "required":
      return " ";
    case "wip":
      return "mdi-cog";
    case "completed":
      return "mdi-check";
  }
  return "";
};
