
import Vue from "vue";
import { mapActions, mapGetters } from "vuex";

export default Vue.extend({
  name: "App",

  data: () => ({
    drawer: true,
  }),

  async created() {
    const userData = await this.$store.dispatch("checkStoredConnection");
    console.log(userData);
    if (userData !== false) this.$router.push("/home");
  },

  computed: {
    ...mapGetters(["isLoggedIn", "loggedUser"]),
    links() {
      const arr = [
        ["mdi-home", "Home", "/home"],
        ["mdi-format-list-bulleted", "Archivio", "/my-reports"],
        ["mdi-magnify", "Ricerca", "/create-report"],
      ];
      if (this.loggedUser.role === "admin") {
        arr.push(["mdi-folder", "Report", "/report"]);
        arr.push(["mdi-delete", "Reset DB", "/reset-db"]);
      }
      return arr;
    },
  },

  methods: {
    ...mapActions(["logout"]),
    toggleDrawer() {
      this.drawer = !this.drawer;
    },
  },
});
