
import { Component, Vue } from "vue-property-decorator";

@Component({
  data: () => ({
    username: null,
    password: null,
    login_error: false,
  }),
  methods: {
    login() {
      this.$store
        .dispatch("login", {
          username: this.$data.username,
          password: this.$data.password,
        })
        .then((result) => {
          if (result) this.$router.push("/home");
          else this.login_error = true;
        })
        .catch(() => alert("there was an error with your login!"));
    },
  },
})
export default class LoginView extends Vue {}
