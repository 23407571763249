
import { Component, Vue } from "vue-property-decorator";
import axios from "axios";

@Component({})
export default class ResetDBView extends Vue {
  dialog1 = true;
  dialog2 = false;

  resetDB() {
    this.dialog1 = false;
    axios.post("/reset-db").then(() => (this.dialog2 = true));
  }

  OK() {
    this.dialog2 = false;
    this.$router.push("/home");
  }
}
